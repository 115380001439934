import React, { FC } from 'react'
import TitleText from '../TitleText/TitleText';
import connectionFunction from '../../images/advisors/image.png';
import styles from './Advisors.module.scss';

const Advisors: FC = () => {
    return (
        <section className={styles.advisors}>
            <div className="container">
                <div className={styles.advisorsImage}>
                    <img src={connectionFunction} alt="Connection Functions" />
                </div>
                <div className={styles.advisorsText}>
                    <TitleText firstTitle="Connect with our" secondTitle="Compliance Advisors"/>
                    <p>Whether you need assistance with legal compliance, risk management, or other compliance-related issues, our advisors can provide you with practical solutions tailored to your specific needs and goals.</p>
                    <a href="/" className="action main">Talk to advisor</a>
                </div>
            </div>
        </section>
    )
}

export default Advisors;