import React, { FC } from 'react'
import styles from './Reviews.module.scss';
import TitleText from '../TitleText/TitleText';

import user_1 from '../../images/reviews/users/1.png';
import user_2 from '../../images/reviews/users/2.png';
import user_3 from '../../images/reviews/users/3.png';
import stars from '../../images/reviews/stars/fiveStars.svg';
import quote from '../../images/quotes.svg';


const Reviews: FC = () => {

    interface ReviewData {
        image: string;
        name: string;
        company: string;
        review: string;
    }
      
    const data: ReviewData[] = [
        {
            image: user_1,
            name: "Priya Patel",
            company: "NextGen Industries Pvt. Ltd.",
            review: "Motta Associates has been our go-to consultancy firm for all our labor law compliance needs. We have been working with them for over a year now and we are extremely satisfied with their services. Their expertise in labor laws is unmatched and Priya Patel, our compliance officer, has been instrumental in ensuring that we are always in compliance. Highly recommended!"
        },
        {
            image: user_2,
            name: "Neha Singh",
            company: "DigiSolutions India Pvt. Ltd.",
            review: "Neha Singh, our HR Manager, has been working with Motta Associates for several years now. We are extremely happy with the level of service we have received from them. Their expertise in labor law compliance has been invaluable to us, and we would highly recommend them to any company looking for reliable and efficient labor law consultancy services."
        },
        {
            image: user_3,
            name: "Sanjay Kumar",
            company: "TechWave Solutions Inc",
            review: "Motta Associates has been an integral part of our company's success. Sanjay Kumar, our compliance officer, has been working with them for many years and has been instrumental in ensuring that we are always in compliance with labor laws. Their expert consultancy services have helped us to operate with complete confidence and we are extremely satisfied with their services."
        },
    ]

    const renderReviews = () => {
        return data.map((item, index) => (
            <li key={index}>
                <div className={styles.reviewsItemHeader}>
                    <img src={item.image} alt="User" />
                    <h4>{item.name}</h4>
                    <span>{item.company}</span>
                    <img src={stars} alt="Stars" />
                </div>
                <img src={quote} alt="Quotes" />
                <p>{item.review}</p>
            </li>
        ))
    }
      
    return (
        <section className={styles.reviews}>
            <div className="container">
                <TitleText firstTitle='Success Stories' secondTitle='To Know About Our Advisors'/>
                <ul className={styles.reviewsWrapper}>
                    {renderReviews()}
                </ul>
            </div>
        </section>
    )
}

export default Reviews;