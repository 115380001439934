import React, { FC } from 'react'
import styles from './Footer.module.scss';
import FooterMenuItem from './FooterMenuItem';
import logo from '../../../images/header/logo.svg';

const Footer: FC = () => {

    const menuItems = [
        {
            title: 'Company',
            links: [
                { link: '/', title: 'About' },
                { link: '/', title: 'Careers' },
                { link: '/', title: 'Blog' },
                { link: '/', title: 'Contacts' },
            ],
        },
        {
            title: 'Support',
            links: [
                { link: '/', title: 'FAQ' },
                { link: '/', title: 'Terms' },
                { link: '/', title: 'Privacy Policy' },
                { link: '/', title: 'Returns & Warranty' },
            ],
        },
        {
            title: 'Get in touch',
            links: [
                { link: 'tel:18153690989', title: '+91-22-555-555-5555' },
                { link: 'mailto:help@mottaassociates.com', title: 'help@mottaassociates.com' },
                { link: 'https://www.google.com/maps/search/?api=1&query=Motta & Associates', title: 'Laxmi Apartments, A/3, Station Rd, above Prince Optician, Godrej & Boyce Industry Estate, Vikhroli West, Mumbai, Maharashtra 400097' },
            ],
        },
    ];

    const renderMenuItems = () => {
        return menuItems.map((item, index) => (
            <FooterMenuItem key={index} title={item.title} links={item.links} />
        ))
    }

    return (
        <footer className={styles.footer}>
            <div className="container">
                <div className={styles.mainWrapper}>
                    <div className={styles.logoWrapper}>
                        <img src={logo} alt="Web Site Logo" />
                        <div className={styles.logoText}>
                            <h2>Motta</h2>
                            <h3>& Associates</h3>
                        </div>
                    </div>
                    <ul className={styles.linksWrapper}>
                        {renderMenuItems()}
                    </ul>
                </div>
                <p className={styles.copyright}>© 1998-2023 Copyright | All Rights Reserved.</p>
            </div>
        </footer>
    )
}

export default Footer;