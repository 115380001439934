import React, { FC } from 'react'
import styles from './Compliance.module.scss';
import Slider from './ComplianceSlider/ComplianceSlider';
import TitleText from '../TitleText/TitleText';

const Compliance: FC = () => {
    return (
        <section className={styles.compliance}>
            <div className="container">
                <div className={styles.complianceText}>
                    <TitleText firstTitle="Trust your future &" secondTitle="Peaceful governance"/>
                    <p>At Motta & Associates, we understand the importance of compliance with labour laws for the future of your business. Trust us to guide you towards peaceful governance with our expert advice and assistance.</p>
                </div>
                <Slider/>
            </div>
        </section>
    )
}

export default Compliance;