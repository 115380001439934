import React, { FC, useEffect, useState } from 'react'
import styles from './Header.module.scss';
import MultiplyItem from './MultiplyItem';
import logo from '../../../images/header/logo.svg';

const Header: FC = () => {
    
    const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);

    const clickOnMobileMenu = () => {
        setIsMobileMenuActive(prev => !prev);
    }

    useEffect(() => {
        //body scroll change
        if (isMobileMenuActive) {
            document.body.className = "disable-scroll";
        } else {
            document.body.className = "";
        }
    }, [isMobileMenuActive]);

    return (
        <header>
            <div className="container">
                <div 
                    className={styles.logo}>
                    <img src={logo} alt="Web Site Logo" />
                    <div className={styles.logoText}>
                        <h2>Motta</h2>
                        <h3>& Associates</h3>
                    </div>
                </div>
                <nav className={`${isMobileMenuActive ? styles.active: ''}`}>
                    <svg className={styles.close} onClick={clickOnMobileMenu} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 15.318 9.182 8.5 16 1.682 15.318 1 8.5 7.818 1.682 1 1 1.682 7.818 8.5 1 15.318l.682.682L8.5 9.182 15.318 16l.682-.682Z" fill="#2D2A26" stroke="#2D2A26"></path></svg>
                    <ul className={`${styles.menu}`}>
                        <li><a href="/"><span>Practice Areas</span></a></li>
                        <li><a href="/"><span>Labor Law</span></a></li>
                        <MultiplyItem title='Audits' links={[{link: '/', title: 'Example 1'}, {link: '/', title: 'Example 2'}, {link: '/', title: 'Example 3'}]} />
                        <li><a href="/users/sign_in" className="action login">Login</a></li>
                    </ul>
                </nav>
                <div 
                className={styles.hamburger} 
                onClick={clickOnMobileMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </header>
    )
}

export default Header;