import React, { FC, useEffect, useState } from 'react'
import styles from './Promo.module.scss';
import circle from '../../images/promo/circle.png';
import team_1 from '../../images/promo/team/1.png';
import team_2 from '../../images/promo/team/2.png';
import team_3 from '../../images/promo/team/3.png';
import camera_icon from '../../images/promo/icons/camera.svg';
import tel_icon from '../../images/promo/icons/tel.svg';
import list_icon from '../../images/promo/icons/list.svg';

const Promo: FC = () => {

    const [activeSlide, setActiveSlide] = useState(0);
    const texts = [ 'First', 'Second', 'Third', 'COMPLIANCE' ];

    useEffect(() => {
        const timer = setTimeout(() => {
            setActiveSlide((prevSlide) => (prevSlide + 1) % texts.length);
        }, 5000);

        return () => clearTimeout(timer);
    }, [activeSlide, texts.length]);
    
    return (
        <section className={styles.promo}>
            <div className="container">
                <div className={styles.backgroundImage}>
                    <img src={circle} alt="Circle With Functions" />
                    <div className={styles.slider}>
                        {texts.map((text, index) => (
                            <div
                                key={index}
                                className={`${styles.slide} ${index === activeSlide ? styles.active : ''}`}
                            >
                                {text}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.tabs}>
                    {texts.map((_, index) => (
                        <div
                            key={index}
                            className={`${styles.tab} ${index === activeSlide ? styles.active : ''}`}
                            onClick={() => setActiveSlide(index)}
                        />
                    ))}
                </div>
                <div className={styles.mainWrapper}>
                    <span>Reliable</span>
                    <h1>Labour Law Consultancy Services with Motta & Associates.</h1>
                    <div className={styles.team}>
                        <img src={team_1} alt="Team Member" />
                        <img src={team_2} alt="Team Member" />
                        <img src={team_3} alt="Team Member" />
                        <span>Expert team of staff you can trust</span>
                    </div>
                    <a href="/" className="action main">Get in touch</a>
                </div>
                <ul className={styles.functionsWrapper}>
                    <li>
                        <img src={camera_icon} alt="Function Elements" />
                        <div className={styles.text}>
                            <h4>Learn</h4>
                            <p>Learn more about our labour law services and expertise.</p>
                        </div>
                    </li>
                    <li>
                        <img src={tel_icon} alt="Function Elements" />
                        <div className={styles.text}>
                            <h4>Connect</h4>
                            <p>Connect with our team of experts for reliable advice.</p>
                        </div>
                    </li>
                    <li>
                        <img src={list_icon} alt="Function Elements" />
                        <div className={styles.text}>
                            <h4>Manage</h4>
                            <p>Get professional assistance with records management.</p>
                        </div>
                    </li>
                </ul>
            </div>

        </section>
    )
}

export default Promo;