import React, { FC } from 'react'
import Promo from './components/Promo/Promo';
import Layout from './components/Layout/Layout';
import Compliance from './components/Compliance/Compliance';
import Advisors from './components/Advisors/Advisors';
import Platform from './components/Platform/Platform';
import Reviews from './components/Reviews/Reviews';
import Privileges from './components/Privileges/Privileges';
import './styles/globals.scss'

const LandingApp: FC = () => {
  return (
    <Layout>
      <Promo/>
      <Compliance/>
      <Advisors/>
      <Platform/>
      <Reviews/>
      <Privileges/>
    </Layout>
  )
}

export default LandingApp;